<div #videoModal class="m-portlet mf-video-container">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    <i class="la la-video-camera"></i>
                    ePosvet
                </h3>
            </div>
        </div>
        <div class="m-portlet__head-tools">
            <ul class="m-portlet__nav">
                <li *ngIf="windowSize === 'small'" class="m-portlet__nav-item">
                    <button
                        (click)="moveSmallVideo()"
                        class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                    >
                        <i
                            class="la"
                            [ngClass]="{
                                'la-caret-square-o-left': modalPosition === 'bottom-right',
                                'la-caret-square-o-up': modalPosition === 'bottom-left',
                                'la-caret-square-o-right': modalPosition === 'top-left',
                                'la-caret-square-o-down': modalPosition === 'top-right'
                            }"
                        ></i>
                    </button>
                </li>
                <li class="m-portlet__nav-item">
                    <button
                        [disabled]="callState !== callStates.CALL_STARTED"
                        (click)="toggleFullScreen()"
                        class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                    >
                        <i *ngIf="windowSize !== 'full'" class="la la-expand"></i>
                        <i *ngIf="windowSize === 'full'" class="la la-compress"></i>
                    </button>
                </li>
                <li class="m-portlet__nav-item">
                    <button
                        [disabled]="callState !== callStates.CALL_STARTED"
                        (click)="toggleModalSize()"
                        class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill"
                    >
                        <i *ngIf="windowSize !== 'small'" class="la la-angle-down"></i>
                        <i *ngIf="windowSize === 'small'" class="la la-angle-up"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="m-portlet__body p-0">
        <div class="video-container">
            <div *ngIf="callState === callStates.CALL_STARTED" class="call-duration">
                {{ callDuration.formatted }}
            </div>
            <div *ngIf="callState === callStates.CALL_STARTED" class="remote-info">
                <div *ngIf="patientReady?.userData?.cameraError" class="text-danger">
                    <i class="la la-video-camera"></i>
                    Pacient nima kamere ali je onemogočil dostop
                </div>
                <div *ngIf="patientReady?.userData?.microphoneError" class="text-danger">
                    <i class="la la-microphone"></i>
                    Pacient nima mikrofona ali je onemogočil dostop
                </div>
                <div *ngIf="patientReady?.userData?.cameraMuted && !patientReady?.userData?.cameraError" class="text-warning">
                    <i class="la la-video-camera"></i>
                    Pacient je izključil kamero
                </div>
                <div *ngIf="patientReady?.userData?.microphoneMuted && !patientReady?.userData?.microphoneError" class="text-warning">
                    <i class="la la-microphone"></i>
                    Pacient je izključil mikrofon
                </div>
                <div *ngIf="patientReady?.userData?.playoutMuted" class="text-warning">
                    <i class="la la-volume-off"></i>
                    Pacient je izključil zvočnike/slušalke
                </div>
            </div>
            <div #remoteVideo id="agora-remote-video" [ngClass]="{ loaded: false }">
                <ng-container *ngIf="callState === callStates.CALL_STARTED">
                    <div *ngIf="!patientReady?.status" class="alert alert-danger">Stranka je zaključila klic ali izgubila povezavo</div>
                </ng-container>
            </div>
            <div
                class="local-video-container"
                [ngClass]="{
                    'call-started': callState === callStates.CALL_STARTED && !showSettings,
                    'call-ended': callState === callStates.CALL_ENDING
                }"
            >
                <ng-container *ngIf="callState < callStates.CALL_STARTED && callState !== callStates.DEVICE_TEST">
                    <div *ngIf="!patientReady?.status && !outsideCallInProgress" class="alert alert-warning">Stranka še ni pripravljena na klic</div>
                    <div *ngIf="patientReady?.status && !outsideCallInProgress" class="alert alert-success">Stranka je pripravljena na klic</div>
                    <div *ngIf="outsideCallInProgress" class="alert alert-danger">
                        Klic že poteka z uporabnikom {{ outsideCallInProgress?.displayName }}
                    </div>
                </ng-container>

                <ng-container *ngIf="callState === callStates.DEVICE_TEST">
                    <div class="alert alert-info">Preverjanje delovanja kamere in mikrofona</div>
                </ng-container>

                <div *ngIf="callState === callStates.CALL_ENDING" class="text-center">
                    <h5 class="font-weight-bold mb-4">Zapustili ste video</h5>
                    <button (click)="leaveCall()" class="btn m-btn--pill btn-accent my-5">Zapri okno</button>
                    <ng-container *ngIf="patientReady?.status">
                        <p>Stranka je še na klicu</p>
                        <p>Klic lahko ponovno vzpostavite</p>
                        <i class="la la-arrow-down"></i>
                    </ng-container>
                </div>

                <div [ngSwitch]="localAvalaible" id="agora-local-video" [ngClass]="{ loaded: localAvalaible === 'READY' }">
                    <ng-container *ngSwitchCase="'READY'"></ng-container>
                    <div *ngSwitchCase="'WAITING'" class="alert alert-info">Čakam na dostop do kamere...</div>
                    <div *ngSwitchCase="'NotAllowedError' || 'PERMISSION_DENIED'" class="alert alert-danger">
                        Dostop do kamere ali mikrofona je zavrnjen! Preverite nastavitve vašega brskalnika ter operacijskega sistema.
                    </div>
                    <div *ngSwitchCase="'NotFoundError' || 'DEVICES_NOT_FOUND'" class="alert alert-danger">
                        Ni ustrezne kamere ali mikrofona! Preverite ali sta kamera in mikrofon priključena in omogočena.
                    </div>
                    <div *ngSwitchCase="'MEDIA_OPTION_INVALID'" class="alert alert-danger">Kamera je že v uporabi ali ni ustrezne kakovosti.</div>
                    <div *ngSwitchCase="'NOT_SUPPORTED'" class="alert alert-danger">Vaš brskalnik ne podpira uporabe kamere.</div>
                    <div *ngSwitchDefault class="alert alert-danger">Pri dostopu do kamere ali mikrofona je prišlo do napake!</div>
                </div>
                <div *ngIf="callState < callStates.CALL_STARTED || showSettings" class="device-settings">
                    <div class="video-settings">
                        <div><i class="la la-video-camera"></i></div>
                        <div>
                            <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                <label>
                                    <input
                                        [checked]="localVideoEnabled"
                                        [disabled]="localAvalaible !== 'READY'"
                                        (change)="toggleVideo()"
                                        type="checkbox"
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        <div class="device-state">
                            <span *ngIf="!localVideoEnabled" class="text-danger">Neaktivno</span>
                            <span *ngIf="localVideoEnabled" class="text-success">Aktivno</span>
                        </div>
                        <div>
                            <div
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                class="btn m-btn m-btn--icon popup-to-show btn-secondary dropdown-button"
                                [ngClass]="{ disabled: localAvalaible !== 'READY' }"
                            >
                                <span *ngIf="activeVideoDevice">{{ activeVideoDevice }}</span>
                                <span *ngIf="!activeVideoDevice">{{ videoDevices[0]?.label ?? 'Kamera' }}&nbsp;</span>
                                <i class="la la-angle-down"></i>
                            </div>
                            <div class="dropdown-menu">
                                <a *ngFor="let camera of videoDevices" (click)="switchCamera(camera)" class="dropdown-item">
                                    {{ camera.label }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="audio-settings">
                        <div><i class="la la-microphone"></i></div>
                        <div>
                            <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                <label>
                                    <input
                                        [checked]="localAudioEnabled"
                                        [disabled]="localAvalaible !== 'READY'"
                                        (change)="toggleAudio()"
                                        type="checkbox"
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        <div class="device-state">
                            <span *ngIf="!localAudioEnabled" class="text-danger">Neaktivno</span>
                            <span *ngIf="localAudioEnabled" class="text-success">Aktivno</span>
                        </div>
                        <div>
                            <div
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                class="btn m-btn m-btn--icon popup-to-show btn-secondary dropdown-button"
                                [ngClass]="{ disabled: localAvalaible !== 'READY' }"
                            >
                                <span *ngIf="activeAudioDevice">{{ activeAudioDevice }}</span>
                                <span *ngIf="!activeAudioDevice">{{ audioDevices[0]?.label ?? 'Mikrofon' }}&nbsp;</span>
                                <i class="la la-angle-down"></i>
                            </div>
                            <div class="dropdown-menu">
                                <a *ngFor="let audio of audioDevices" (click)="switchAudio(audio)" class="dropdown-item">
                                    {{ audio.label }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="playout-settings">
                        <div>
                            <i class="la la-volume-up"></i>
                        </div>
                        <div>
                            <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                <label>
                                    <input
                                        [checked]="audioVolume > 0 && playoutDevices.length > 0"
                                        [disabled]="localAvalaible !== 'READY'"
                                        (change)="toggleAudioVolume()"
                                        type="checkbox"
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        <div class="device-state">
                            <span *ngIf="audioVolume === 0" class="text-danger">Neaktivno</span>
                            <span *ngIf="audioVolume > 0" class="text-success">Aktivno</span>
                        </div>
                        <div>
                            <div
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                class="btn m-btn m-btn--icon popup-to-show btn-secondary dropdown-button"
                                [ngClass]="{ disabled: localAvalaible !== 'READY' }"
                            >
                                <span *ngIf="activePlayoutDevice">{{ activePlayoutDevice.label }}</span>
                                <span *ngIf="!activePlayoutDevice">Zvočniki</span>
                                <i class="la la-angle-down"></i>
                            </div>
                            <div class="dropdown-menu">
                                <a *ngFor="let playout of playoutDevices" (click)="switchPlayoutDevice(playout)" class="dropdown-item">
                                    {{ playout.label }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="callState === callStates.CALL_STARTED && showSettings" class="mt-3 text-center">
                    <button (click)="toggleSettings(false)" class="btn m-btn--pill btn-accent">
                        <i class="la la-close"></i>
                        Zapri nastavitve
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="m-portlet__foot">
        <div class="action-buttons">
            <ng-container *ngIf="callState < callStates.CALL_STARTED">
                <button
                    *ngIf="callState !== callStates.DEVICE_TEST"
                    [disabled]="!patientReady?.status || localAvalaible !== 'READY'"
                    (click)="startCall()"
                    class="btn m-btn--pill btn-accent"
                >
                    <i class="la la-phone"></i>
                    Začni klic
                </button>
                <button (click)="closeCallWindow()" class="btn m-btn--pill btn-danger">
                    <i class="la la-close"></i>
                    Zapri
                </button>
            </ng-container>
            <ng-container *ngIf="callState === callStates.CALL_STARTED || (callState === callStates.CALL_ENDING && patientReady?.status)">
                <button
                    [disabled]="callState === callStates.CALL_ENDING"
                    (click)="toggleAudio()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                    [ngClass]="{ 'btn-metal': localAudioEnabled, 'btn-danger btn-icon-disabled': !localAudioEnabled }"
                >
                    <i class="la la-microphone"></i>
                </button>

                <button
                    [disabled]="callState === callStates.CALL_ENDING"
                    (click)="toggleVideo()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                    [ngClass]="{ 'btn-metal': localVideoEnabled, 'btn-danger btn-icon-disabled': !localVideoEnabled }"
                >
                    <i class="la la-video-camera"></i>
                </button>

                <button
                    *ngIf="callState === callStates.CALL_STARTED"
                    (click)="endCall()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill btn-danger btn-lg"
                >
                    <i class="la la-phone"></i>
                </button>

                <button
                    *ngIf="callState === callStates.CALL_ENDING"
                    (click)="restartCall()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill btn-success btn-lg"
                >
                    <i class="la la-phone"></i>
                </button>

                <button
                    [disabled]="callState === callStates.CALL_ENDING"
                    (click)="setAudioVolume()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                    [ngClass]="{ 'btn-metal': audioVolume > 0, 'btn-danger btn-icon-disabled': audioVolume === 0 }"
                >
                    <i *ngIf="audioVolume >= 75" class="la la-volume-up"></i>
                    <i *ngIf="audioVolume > 0 && audioVolume < 75" class="la la-volume-down"></i>
                    <i *ngIf="audioVolume === 0" class="la la-volume-off"></i>
                </button>

                <button
                    [disabled]="callState === callStates.CALL_ENDING"
                    (click)="toggleSettings()"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                    [ngClass]="{ 'btn-metal': !showSettings, 'btn-info': showSettings }"
                >
                    <i class="la la-gear"></i>
                </button>
            </ng-container>
        </div>
    </div>
</div>
