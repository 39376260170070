export enum AppModulesType {
    ACCOUNTING = 'ACCOUNTING',
    PATIENT_APP = 'PATIENT_APP',
    CASE = 'CASE',
    WAITING_QUEUE = 'WAITING_QUEUE',
    TRIAGE = 'TRIAGE',
    TREATMENTS = 'TREATMENTS',
    TELEMEDICINE = 'TELEMEDICINE',
    INBOX = 'INBOX',
    DEVICES_INBOX = 'DEVICES_INBOX',
    COVID = 'COVID',
    AVAIL_PARTNERS = 'AVAIL_PARTNERS',
    WIDGET_INQUIRIES = 'WIDGET_INQUIRIES',
    WIDGETS = 'WIDGETS',
    WIDGETS_PAYMENT_GATEWAY = 'WIDGETS_PAYMENT_GATEWAY',
    SEND_CONTENT_CONTRACTOR = 'SEND_CONTENT_CONTRACTOR',
    INQUIRIES = 'INQUIRIES',
    DENTIST = 'DENTIST',
    KANBAN = 'KANBAN',
    HELLO_DOCTOR = 'HELLO_DOCTOR',
    SURVEYS = 'SURVEYS',
    TELEPHONY = 'TELEPHONY',
    WEB_COUPONS = 'WEB_COUPONS',
    MARKETING = 'MARKETING',
    LEADS = 'LEADS',
    OIM_TRACKING = 'OIM_TRACKING',
    ONBOARDING_WIZARD = 'ONBOARDING_WIZARD',
    DOCUMENTATION_OVERVIEW = 'DOCUMENTATION_OVERVIEW',
    SUBCONTRACTOR_AVAILABILITY = 'SUBCONTRACTOR_AVAILABILITY',
    PREVENTIVE = 'PREVENTIVE',
    RECURRING = 'RECURRING',
    ESZBO = 'ESZBO',
    OCCUPATIONAL_MEDICINE = 'OCCUPATIONAL_MEDICINE',
    LABORATORY = 'LABORATORY',
    REPORTS = 'REPORTS',
    EA_REPORT_CLAIM = 'EA_REPORT_CLAIM',
}
