import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { User } from '../../../../_shared/model';

@Component({
    selector: 'common-subcontractor-icon',
    templateUrl: './subcontractor-icon.component.html',
    styleUrls: ['./subcontractor-icon.component.scss'],
})
export class SubcontractorIconComponent implements OnInit {
    @Input()
    user: User;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    public getInitials(): string {
        const name: string[] = _.get(this.user, 'name', '? ?').trim().split(' ');
        if (name.length > 1) {
            return `${name[0][0]}${name[1][0]}`;
        } else {
            return `${name[0][0]}}`;
        }
    }
}
